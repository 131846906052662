.contentImage {
  width: 400px;
  padding: 1rem;
  padding-top: 1 rem;
}

.disabled-textfield .MuiInputLabel-root.Mui-disabled {
  color: #000000;
  /* Cambia el color de la etiqueta */
}

/* Cambia el color del texto en el input deshabilitado */
.disabled-textfield .MuiInputBase-input.Mui-disabled {
  color: #000000;
  /* Color del texto */
  -webkit-text-fill-color: #000000;
  /* Requerido para algunos navegadores */
  opacity: 1;
  /* Necesario para sobrescribir el estilo por defecto de opacidad en MUI */
}